(function ($) {
  fields_are_initialized = false;
  function change_form_label(label, input) {
    if ("" !== input.val()) {
      $(label).addClass("active");
    }
    $(label).on("click", function () {
      $(input).focus();
    });
    $(input).on("focus", function () {
      $(label).addClass("active");
    }).on("focusout", function () {
      var value = $.trim($(this).val());
      if (value.length === 0 || $(this).val() === "(___) ___-____") {
        $(label).removeClass("active");
      }
    });
  }
  function add_form_events() {
    $(".gfield:not(.gfield--radio):not(.gfield--has-multiple-inputs)").each(function () {
      var $field = $(this);
      if ($field.hasClass("initialized")) {
        return;
      }
      var classesExcluded = ["ginput-wrapper--radio", "ginput-wrapper--checkbox", "ginput-wrapper--consent", "ginput-wrapper--select"];
      for (var i = 0; i < classesExcluded.length; i++) {
        if ($field.hasClass(classesExcluded[i])) {
          return;
        }
      }
      var $label;
      var $input;
      $field.addClass("initialized");
      $label = $field;
      $input = $field.find(".ginput_container input , .ginput_container textarea");
      change_form_label($label, $input);
      if ($field.hasClass("gfield--select")) {
        var $itemSelect = $field.find(".gfield_select");
        var tomselectOption = {
          create: false,
          optionClass: "vtx_select__option",
          itemClass: "vtx_select__item",
          controlInput: false,
          allowEmptyOption: true //fixrs added true to allow placeholder
        };
        new TomSelect($itemSelect[0], tomselectOption);
      }
    });
  }
  function change_file_upload() {
    var multiFileUploads = document.querySelectorAll(".gform_fileupload_multifile");
    if (0 < multiFileUploads.length) {
      multiFileUploads.forEach(function (fileUpload) {
        fileUpload.querySelector(".gform_drop_instructions").textContent = wp.i18n._x("Drag and drop file to upload or", "drag and drop instruction", "vtx");
        fileUpload.querySelector(".gform_button_select_files").innerHTML = "<span>" + wp.i18n._x("Select files", "drag and drop instruction", "vtx") + "</span>";
        fileUpload.querySelector(".gform_button_select_files").classList.add("btn--outline");
      });
    }
  }
  document.addEventListener("gform_post_render", function () {
    fields_are_initialized = true;
    change_file_upload();
    add_form_events();
  });
  window.addEventListener("load", function () {
    if (!fields_are_initialized) {
      change_file_upload();
      add_form_events();
    }
  });
})(jQuery);